$navbar-height: 51px;

app-main-layout {
  @extend .d-flex;
  height: 100%;
}

app-navbar {
  height: $navbar-height;
  z-index: 100;
  //box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

}

app-sidebar {
  //width: 280px;
  height: 100vh;
  overflow-x: hidden;
    .scrollable-content {
        height: calc(100vh - 120px);
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

//app-sidebar [top] {
//  box-shadow: 0px 10px 15px 0px rgba(34, 60, 80, 0.5);
//}
//
//app-sidebar [bottom] {
//  box-shadow: 0px -10px 15px 0px rgba(34, 60, 80, 0.5);
//}

.router-container {
  @extend .d-flex;
  @extend .flex-grow-1;
  //@extend .container-fluid;
  //@extend .p-3;
  //@extend .card;
  //@extend .shadow-sharp;

  > :not(router-outlet) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100vh - #{$navbar-height} - 1 * #{$spacer});
    -webkit-overflow-scrolling: touch;
    overflow-y: overlay;
    border-radius: $border-radius * 2;
  }
}

.main-view-wrapper {
  @extend .flex-grow-1;
  position: relative;
}

.main-view {
  position: absolute;
  @extend .d-flex;
  @extend .flex-column;
  width: 100%;
}


.offscreen-right {
  top: calc(0px);
  right: 0;
  height: 100vh;
  z-index: 1045
}
