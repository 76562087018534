app-pagination-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;

  .table-footer {
    box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);
    width: 100%;
    background: $table-background;
    position: sticky;
    bottom: 0;
    left: 0;
  }

  app-table-paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

}
