$scrollbar-thumb-color: $primary !default;
$scroll-border-color: darken($scrollbar-thumb-color, 30);
$scroll-track-color: lighten($secondary, 20);
$scrollbar-thumb-size: 10px;
$scrollbar-thumb-size-plus: 13px;

::-webkit-scrollbar {
    background: transparent;
    overflow: visible;
    width: $scrollbar-thumb-size;
    height: $scrollbar-thumb-size;
}

::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
    border-radius: $border-radius * 2;
}

::-webkit-scrollbar-thumb:hover {
    background-color: darken($scrollbar-thumb-color, 10);
}

::-webkit-scrollbar-track {
    background-color: $scroll-track-color;
}

/* Buttons */
::-webkit-scrollbar-button:single-button {
    background-color: $scroll-track-color;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
}

::-webkit-scrollbar-button:single-button:hover {
    background-color: darken($scroll-track-color, 10);
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: $scrollbar-thumb-size;
    width: $scrollbar-thumb-size-plus;
    background-position: left;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: $scrollbar-thumb-size;
    width: $scrollbar-thumb-size-plus;
    background-position: right;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: $scrollbar-thumb-size-plus;
    width: $scrollbar-thumb-size;
    background-position: bottom;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
    height: $scrollbar-thumb-size-plus;
    width: $scrollbar-thumb-size;
    background-position: bottom;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
}
