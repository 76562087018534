@font-face {
    font-family: 'Golos UI';
    src:
            url('golos-ui_bold.woff2') format('woff2'),
            url('golos-ui_bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Golos UI';
    src:
            url('golos-ui_medium.woff2') format('woff2'),
            url('golos-ui_medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Golos UI';
    src:
            url('golos-ui_regular.woff2') format('woff2'),
            url('golos-ui_regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Golos UI VF';
    src:
            url('golos-ui_vf.woff2') format('woff2'),
            url('golos-ui_vf.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
