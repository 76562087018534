app-grouped-widget-component {
    .grouped-widget-title-wrapper {
        @extend .p-3;
        border-bottom: 1px solid $gray-300;

        .grouped-widget-title {
            border-left: 3px solid $primary;
            margin-bottom: $spacer;
            margin-left: -$spacer;
            padding-left: $spacer;
        }
    }
}

app-selectable-grouped-widget-component {
    .title-wrapper {
        @extend .p-3;
        @extend .d-flex;
        @extend .flex-column;
        border-bottom: 1px solid $gray-300;
    }
    .list-group {
        li {
            @extend .p-3;
            @extend .d-flex;
            @extend .flex-column;
        }
    }
}

.chart-wrapper {
    .chart-title {
        color: $text-muted;
    }
}
