app-consolidated-report-vew-component {
  @extend .border-0;
  @extend .shadow-sharp;
  @extend .bg-white;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 1px;

  table {
    position: relative;

    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
    }
    //th[scope=row] {
    //  position: -webkit-sticky;
    //  position: sticky;
    //  left: 0;
    //  z-index: 1;
    //}
  }

  table > app-consolidated-report-table-row-component {

    display: table-row-group;

    app-consolidated-report-table-row-component {
      display: table-row;
    }

  }
}

