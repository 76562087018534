$btn-navbar-color: $gray-700 !default;
$btn-navbar-hover-color: $body-color !default;

.btn-navbar {
  @extend .animated;
  align-self: stretch;
  box-shadow: none !important;
  background-color: transparent;
  border: none;
  color: $btn-navbar-color;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .9em;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, .1);
    color: $btn-navbar-hover-color;
  }

  i {
    margin-right: .25rem;
  }

  a {
    color: $btn-navbar-color !important;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .btn-navbar {
    flex-wrap: wrap;
  }
}

@include media-breakpoint-only(sm) {
  .btn-navbar {
    white-space: pre-wrap;
    font-size: .8em;
  }
}

@include media-breakpoint-only(xs) {
  .btn-navbar {
    font-size: 0;
  }

  .btn-navbar i {
    font-size: initial;
    margin-right: 0;
  }
}

@each $color, $value in $theme-colors {
  .btn-navbar.btn-#{$color} {
    &:hover {
      background: linear-gradient(to bottom, rgba($value, .1) 0%, rgba($value, .2) 50%, rgba($value, .1) 100%);
    }
  }
}
