app-notification {
    .delete {
        font-size: 1.5rem;
        line-height: 0.5rem;
        opacity: .8;
        padding: 0;
    }
}
app-notifications-chip {
    .badge {
        background-color: $danger;
        font-size: .65rem;
        font-weight: normal;
        border-radius: 10px;
        right: -8px;
        top: 4px;
    }
}
