formula-input {
    .wrapper {
        width: 300px;
    }
    .tags .badge {
        background-color: lighten($warning, 40);
        font-weight: normal;
        color: black;
        cursor: pointer;
    }
    .controls {
        right: 0;
        top: 0;
    }
    input {
        width: calc(100% - 60px);
    }
}
