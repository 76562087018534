@mixin rtl {
    @at-root [dir="rtl"] #{&} {
        @content
    }
}


$ng-select-highlight: #007eff !default;
$ng-select-primary-text: #333 !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: #ccc !default;
$ng-select-border-radius: 4px !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: lighten($ng-select-highlight, 46) !default;
$ng-select-selected-text: $ng-select-primary-text !default;

$ng-select-marked: lighten($ng-select-highlight, 48) !default;
$ng-select-marked-text: $ng-select-primary-text !default;

$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: 36px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9rem !default;
$ng-select-value-text: $ng-select-primary-text !default;

$ng-select-dropdown-bg: $ng-select-bg !default;
$ng-select-dropdown-border: $ng-select-border !default;
$ng-select-dropdown-optgroup-text: rgba(0, 0, 0, 0.54) !default;
$ng-select-dropdown-optgroup-marked: $ng-select-dropdown-optgroup-text !default;
$ng-select-dropdown-option-bg: $ng-select-dropdown-bg !default;
$ng-select-dropdown-option-text: rgba(0, 0, 0, 0.87) !default;
$ng-select-dropdown-option-disabled: lighten($ng-select-primary-text, 60) !default;

$ng-select-input-text: #000000 !default;

.ng-select {
    &.ng-select-opened {
        > .ng-select-container {
            background: $ng-select-bg;
            border-color: darken($ng-select-border, 10) $ng-select-border lighten($ng-select-border, 5);

            &:hover {
                box-shadow: none;
            }

            .ng-arrow-wrapper {
                @extend .bi-chevron-up;
            }

            //.ng-arrow {
            //    top: -2px;
            //    border-color: transparent transparent darken($ng-select-border, 20);
            //    border-width: 0 5px 5px;
            //
            //    &:hover {
            //        border-color: transparent transparent darken($ng-select-border, 60);
            //    }
            //}
        }

        &.ng-select-top {
            > .ng-select-container {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }

        &.ng-select-right {
            > .ng-select-container {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.ng-select-bottom {
            > .ng-select-container {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.ng-select-left {
            > .ng-select-container {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &.ng-select-focused {
        &:not(.ng-select-opened) > .ng-select-container {
            border-color: $ng-select-highlight;
            box-shadow: $ng-select-box-shadow;
        }
    }

    &.ng-select-disabled {
        > .ng-select-container {
            background-color: $ng-select-disabled-text;
        }
    }

    .ng-has-value .ng-placeholder {
        display: none;
    }

    .ng-select-container {
        color: $ng-select-primary-text;
        background-color: $ng-select-bg;
        border-radius: $ng-select-border-radius;
        border: 1px solid $ng-select-border;
        min-height: $ng-select-height;
        align-items: center;

        &:hover {
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        }

        .ng-value-container {
            align-items: center;
            padding-left: $ng-select-value-padding-left;
            @include rtl {
                padding-right: $ng-select-value-padding-left;
                padding-left: 0;
            }

            .ng-placeholder {
                color: $ng-select-placeholder;
            }
        }
    }

    &.ng-select-single {
        .ng-select-container {
            height: $ng-select-height;

            .ng-value-container {
                .ng-input {
                    top: 5px;
                    left: 0;
                    padding-left: $ng-select-value-padding-left;
                    padding-right: 50px;
                    @include rtl {
                        padding-right: $ng-select-value-padding-left;
                        padding-left: 50px;
                    }
                }
            }
        }
    }

    &.ng-select-multiple {
        &.ng-select-disabled {
            > .ng-select-container .ng-value-container .ng-value {
                background-color: $ng-select-disabled-text;
                border: 1px solid lighten($ng-select-border, 10);

                .ng-value-label {
                    padding: 0 5px;
                }
            }
        }

        .ng-select-container {
            .ng-value-container {
                padding-top: 5px;
                padding-left: 0;
                @include rtl {
                    padding-right: 7px;
                    padding-left: 0
                }

                .ng-value {
                    font-size: $ng-select-value-font-size;
                    //margin-bottom: 5px;
                    color: $ng-select-value-text;
                    background-color: $ng-select-selected;
                    border-radius: 2px;
                    margin-right: 5px;
                    @include rtl {
                        margin-right: 0;
                        margin-left: 5px;
                    }

                    &.ng-value-disabled {
                        background-color: $ng-select-disabled-text;

                        .ng-value-label {
                            padding-left: 5px;
                            @include rtl {
                                padding-left: 0;
                                padding-right: 5px;
                            }
                        }
                    }

                    .ng-value-label {
                        display: inline-block;
                        padding: 1px 5px;
                    }

                    .ng-value-icon {
                        display: inline-block;
                        padding: 1px 5px;

                        &:hover {
                            background-color: darken($ng-select-selected, 5);
                        }

                        &.left {
                            border-right: 1px solid darken($ng-select-selected, 10);
                            @include rtl {
                                border-left: 1px solid darken($ng-select-selected, 10);
                                border-right: none;
                            }
                        }

                        &.right {
                            border-left: 1px solid darken($ng-select-selected, 10);
                            @include rtl {
                                border-left: 0;
                                border-right: 1px solid darken($ng-select-selected, 10);
                            }
                        }
                    }
                }

                .ng-input {
                    //padding: 0 0 3px 3px;
                    @include rtl {
                        padding: 0 3px 3px 0;
                    }

                    > input {
                        color: $ng-select-input-text;
                    }
                }

                .ng-placeholder {
                    top: 5px;
                    padding-bottom: 5px;
                    padding-left: 3px;
                    @include rtl {
                        padding-right: 3px;
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .ng-clear-wrapper {
        color: darken($ng-select-border, 20);

        &:hover .ng-clear {
            color: #D0021B;
        }
    }

    .ng-spinner-zone {
        padding: 5px 5px 0 0;

        @include rtl {
            padding: 5px 0 0 5px;
        }
    }

    .ng-arrow-wrapper {
        width: 25px;
        //padding-right: 5px;
        @include rtl {
            padding-left: 5px;
            padding-right: 0;
        }
        @extend .bi;
        @extend .bi-chevron-down;
        color: $primary;
        //&:hover {
        //    .ng-arrow {
        //        border-top-color: darken($ng-select-border, 40);
        //    }
        //}
        //.ng-arrow {
        //    border-color: darken($ng-select-border, 20) transparent transparent;
        //    border-style: solid;
        //    border-width: 5px 5px 2.5px;
        //    top: 2px;
        //}
    }
}

.ng-dropdown-panel {
    background-color: $ng-select-dropdown-bg;
    border: 1px solid $ng-select-dropdown-border;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0;

    &.ng-select-top {
        bottom: 100%;
        border-top-right-radius: $ng-select-border-radius;
        border-top-left-radius: $ng-select-border-radius;
        border-bottom-color: lighten($ng-select-border, 10);
        margin-bottom: -1px;

        .ng-dropdown-panel-items {
            .ng-option {
                &:first-child {
                    border-top-right-radius: $ng-select-border-radius;
                    border-top-left-radius: $ng-select-border-radius;
                }
            }
        }
    }

    &.ng-select-right {
        left: 100%;
        top: 0;
        border-top-right-radius: $ng-select-border-radius;
        border-bottom-right-radius: $ng-select-border-radius;
        border-bottom-left-radius: $ng-select-border-radius;
        border-bottom-color: lighten($ng-select-border, 10);
        margin-bottom: -1px;

        .ng-dropdown-panel-items {
            .ng-option {
                &:first-child {
                    border-top-right-radius: $ng-select-border-radius;
                }
            }
        }
    }

    &.ng-select-bottom {
        top: 100%;
        border-bottom-right-radius: $ng-select-border-radius;
        border-bottom-left-radius: $ng-select-border-radius;
        border-top-color: lighten($ng-select-border, 10);
        margin-top: -1px;

        .ng-dropdown-panel-items {
            .ng-option {
                &:last-child {
                    border-bottom-right-radius: $ng-select-border-radius;
                    border-bottom-left-radius: $ng-select-border-radius;
                }
            }
        }
    }

    &.ng-select-left {
        left: -100%;
        top: 0;
        border-top-left-radius: $ng-select-border-radius;
        border-bottom-right-radius: $ng-select-border-radius;
        border-bottom-left-radius: $ng-select-border-radius;
        border-bottom-color: lighten($ng-select-border, 10);
        margin-bottom: -1px;

        .ng-dropdown-panel-items {
            .ng-option {
                &:first-child {
                    border-top-left-radius: $ng-select-border-radius;
                }
            }
        }
    }

    .ng-dropdown-header {
        border-bottom: 1px solid $ng-select-border;
        padding: 5px 7px;
    }

    .ng-dropdown-footer {
        border-top: 1px solid $ng-select-border;
        padding: 5px 7px;
    }

    .ng-dropdown-panel-items {
        .ng-optgroup {
            user-select: none;
            padding: 8px 10px;
            font-weight: 500;
            color: $ng-select-dropdown-optgroup-text;
            cursor: pointer;

            &.ng-option-disabled {
                cursor: default;
            }

            &.ng-option-marked {
                background-color: $ng-select-marked;
            }

            &.ng-option-selected, &.ng-option-selected.ng-option-marked {
                color: $ng-select-dropdown-optgroup-marked;
                background-color: $ng-select-selected;
                font-weight: 600;
            }
        }

        .ng-option {
            background-color: $ng-select-dropdown-option-bg;
            color: $ng-select-dropdown-option-text;
            padding: 8px 10px;

            &.ng-option-selected, &.ng-option-selected.ng-option-marked {
                color: $ng-select-selected-text;
                background-color: $ng-select-selected;

                .ng-option-label {
                    font-weight: 600;
                }
            }

            &.ng-option-marked {
                background-color: $ng-select-marked;
                color: $ng-select-marked-text;
            }

            &.ng-option-disabled {
                color: $ng-select-dropdown-option-disabled;
            }

            &.ng-option-child {
                padding-left: 22px;
                @include rtl {
                    padding-right: 22px;
                    padding-left: 0;
                }
            }

            .ng-tag-label {
                font-size: 80%;
                font-weight: 400;
                padding-right: 5px;
                @include rtl {
                    padding-left: 5px;
                    padding-right: 0;
                }
            }
        }
    }

    @include rtl {
        direction: rtl;
        text-align: right;
    }
}

.input-group-sm .ng-select-container,
.sm .ng-select-container {
    padding: 0.25rem 0.5rem !important;
    min-height: auto;
}

.input-group-sm .ng-value-container,
.sm .ng-value-container {
    padding: 0 !important;
}

.ng-select.ng-select-opened > .ng-select-container:hover {
    box-shadow: none;
}

//.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
//  top: -2px;
//  border-color: transparent transparent #999;
//  border-width: 0 5px 5px;
//}

//.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
//  border-color: transparent transparent #666;
//}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    // border-color: #007eff;
    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)
}

.ng-select.ng-select-disabled > .ng-select-container {
    background-color: $input-disabled-bg;

    .ng-arrow-wrapper {
        opacity: 0;
    }
}

.ng-select .ng-has-value .ng-placeholder {
    display: none;
}

.ng-select:not(.form-control) .ng-select-container {
    @extend .form-control;
    align-items: center;
    display: flex;

    .ng-arrow-wrapper {
        @extend .ml-auto !optional;
    }

    /*  background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 36px;
    align-items: center */
}

.ng-select.form-control {
    @extend .p-0;

    .ng-clear-wrapper,
    .ng-arrow-wrapper {
        display: flex;
        align-items: center;
    }

    .ng-arrow-wrapper {
        top: 2px;
    }
}

.ng-select .ng-select-container:hover {
    // box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06)
}

.ng-select .ng-select-container .ng-value-container {
    align-items: center;
    // padding-left: 10px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #aaa;
}

.ng-select.ng-select-single .ng-select-container {
    height: 100%;
}

.ng-select.ng-select-multiple .ng-select-container {
    height: auto;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    left: 0;
    // padding-left: 10px;
    // padding-right: 50px;
    top: 5px;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
    background-color: #f9f9f9;
    // border: 1px solid #e3e3e3;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
    padding: 0 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    // padding-left: 7px;
    // padding-top: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: .9em;
    margin-right: 5px;
    //margin-bottom: 5px;
    background-color: #f5faff;
    border-radius: 2px;
    border: 1px solid #c2e0ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
    background-color: #f9f9f9;
    border: 1px solid #e3e3e3;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
    // padding-left: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    display: inline-block;
    padding: 0 5px 0 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: inline-block;
    padding: 0 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: #d8eafd;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid #c2e0ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 1px solid #c2e0ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    // padding-bottom: 3px;
    // padding-left: 3px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 5px;
    // padding-bottom: 5px;
    // padding-left: 3px;
}

.ng-select .ng-clear-wrapper {
    color: #999;
}

.ng-select .ng-clear-wrapper .ng-clear:hover {
    color: #d0021b;
}

.ng-select .ng-spinner-zone {
    // padding-right: 5px;
    // padding-top: 5px;
}

.ng-select .ng-arrow-wrapper {
    // padding-right: 5px;
    width: 25px;
}

//.ng-select .ng-arrow-wrapper:hover .ng-arrow {
//  border-top-color: #666;
//}

//.ng-select .ng-arrow-wrapper .ng-arrow {
//  border-color: #999 transparent transparent;
//  border-style: solid;
//  border-width: 5px 5px 2.5px;
//}

.ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .06);
}

.ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: #e6e6e6;
    margin-top: -1px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.ng-dropdown-panel.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px;
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid #ccc;
    padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid #ccc;
    padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
    margin-bottom: 1px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    user-select: none;
    padding: 8px 10px;
    font-weight: 500;
    color: rgba(0, 0, 0, .54);
    cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    background-color: rgba(map-get($theme-colors, "primary"), .1);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
    background-color: #f5faff;
    font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0, 0, 0, .87);
    padding: 8px 10px;
    white-space: normal !important;
    overflow: auto !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .form-check-input {
    margin-right: 0.5rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    color: #333;
    background-color: #f5faff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
    font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: rgba(map-get($theme-colors, "primary"), .1);
    color: #333;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: #ccc;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    // padding-left: 22px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    // padding-right: 5px;
    font-size: 80%;
    font-weight: 400;
}

.ng-select.ng-select-single .ng-value-container { // PORTAL-417
    flex-grow: 1;
    width: 1px;
}
