
app-vacancy-control-cell {
  .btn-container button {
    visibility: hidden;
    transition: all .1s ease-in;
    opacity: 0;
  }
}

tr:hover app-vacancy-control-cell .btn-container button {
  visibility: visible;
  opacity: 1;
}
