$col-cell-width: auto;
$row-cell-height: 42px;
$col-height: 80px;
$footer-height: 50px;
$changed-color: lighten($warning, 40);
$auto-calc-off-color: lighten($primary, 20);
$common-block-color: #DDDDDD;
$first-block-color: #D9E1F2;
$second-block-color: #FFFFCC;
$third-block-color: #CCFFCC;
$fourth-block-color: #FCE4D6;
$fifth-block-color: #EDEDED;
$sixth-block-color: #FFCCCC;
$seventh-block-color: #eaffc1;
$eighth-block-color: #ffe699;
$soc-contr-block-color: #F2E9FF;
$fired-color: #efefef;
$added-color: lighten($primary, 50);
$removed-color: lighten($danger, 40);


//app-consolidated-report-vew-component {
//
//
//
//  .scrolling-area {
//
//    * {
//      font-size: 1rem !important;
//    }
//
//    .scrollable-content {
//      width: 100% !important;
//
//      .panel-group {
//        > .panel {
//          &:not(:last-child) {
//            .panel.card.panel-default {
//              //border: none;
//            }
//          }
//
//          > .panel.card.panel-default {
//
//            border-radius: 0;
//            //border: none;
//
//            .panel-heading.card-header {
//              //border: none;
//              //background-color: white;
//              padding-left: 0;
//              padding-right: 0;
//              padding-top: 0;
//              padding-bottom: 0;
//            }
//
//            .panel-body.card-body {
//              padding: 0;
//              //border: none;
//            }
//          }
//        }
//      }
//    }
//  }
//  .indicator {
//    color: $body-color;
//    text-align: end;
//  }
//}

app-wage-fund-full-view,
app-wage-budget,
app-group-plan,
app-wage-activity-view-component {
  @extend .border-0;
  @extend .shadow-sharp;
  @extend .bg-white;

  * {
    font-size: 0.85rem;
  }

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 1px;

  .scrolling-area {
    //@extend table;
    //@extend .table;
    margin-bottom: 0;

    .scrollable-content {
      width: auto;
      height: auto;
    }

    flex-grow: 1;
    height: 1px;
    contain: strict;
    transform: translateZ(0);
    will-change: scroll-position;

    .header-decoration {
      //@extend .border;
      background: #fff;
      box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);
      border-bottom: 1px solid #dee2e6 !important;
      //&:nth-of-type(even) {
      //    background-color: rgba(0, 0, 0, .05);
      //}
      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }

    .vertical-scroller {
      margin: 0;
      // box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);

      > .scrollable-content {
        display: flex;
      }
    }

    .col-header,
    .cell {
      @extend .px-3;
      width: $col-cell-width;
    }

    .row-header,
    .cell {
      height: $row-cell-height;
      position: relative;

      > * {
        width: 100%;
        height: 100%;
      }

      &.hovered {
        box-shadow: 0 0 10px rgb(0 0 0 / 0.3);
        background-color: white;
        position: relative;
        z-index: 1;

        &:before {
          display: block;
          content: "";
          background-color: white;
          width: 8px;
          height: 100%;
          position: absolute;
          left: -8px;
          top: 0;
        }

        &.fired:before {
          background-color: $fired-color;
        }

        &.added:before {
          background-color: $added-color;
        }

        &.changed:before {
          background-color: $changed-color;
        }

        &.removed:before {
          background-color: $removed-color;
        }

        > * {
          font-weight: 500;
        }
      }

      &.fired {
        background-color: $fired-color;
      }

      &.added {
        background-color: $added-color;
      }

      &.removed {
        background-color: $removed-color;
      }

      &.changed {
        background-color: $changed-color;
          $start: 1;
          $end: 12;
          @for $i from $start through $end {
              &.changed_#{$i} {
                  &:before {
                      background-color: white;
                  }
                  table {
                      &:before {
                          display: block;
                          content: "";
                          background-color: white;
                          width: 17px;
                          height: 100%;
                          position: absolute;
                          left: -3px;
                          top: 0;
                      }
                      background-color: white;
                      td:nth-child(#{$i}) {
                          background-color: $changed-color;
                      }

                      td:last-child {
                          background-color: $changed-color;
                      }
                  }
              }
              &.changed_1 {
                  &:before {
                      background-color: $changed-color;
                  }
                  table {
                      &:before {
                          background-color: $changed-color;
                      }
                  }
              }
          }
      }
    }

    .row-header {
      &.hovered {
        &:before {
          display: block;
          content: "";
          background-color: rgb(0 0 0 / 0.3);
          width: $spacer * 0.5;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }

        &:after {
          //display: block;
          //content: "";
          //background-color: $warning;
          //width: 1rem ;
          //height: 100%;
          //position: absolute;
          //right: 0;
          //top: 0;
        }
      }
    }

    .col-header,
    .row-header {
      @extend th;
      @extend .header-decoration;
    }

    .footer-cell {
      @extend .header-decoration;
      position: sticky;
      bottom: -1px;
      height: $footer-height;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @extend .px-3;
      border-top: 1px solid darken($common-block-color, 10) !important;
      background-color: $common-block-color;
      font-weight: 500 !important;
      z-index: 2;
    }

    .col-header {
      position: sticky;
      top: 0;
      z-index: 3;
      display: flex;
      align-items: flex-start;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      justify-content: flex-start;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      height: $col-height;

      .col-header-caption {
        max-width: 12rem;
        text-align: start;
        white-space: normal !important;
      }

      &.common-block {
        background-color: $common-block-color;
      }

      &.first-block {
        background-color: $first-block-color;
      }

      &.second-block {
        background-color: $second-block-color;
      }

      &.third-block {
        background-color: $third-block-color;
      }

      &.fourth-block {
        background-color: $fourth-block-color;
      }

      &.fifth-block {
        background-color: $fifth-block-color;
      }

      &.sixth-block {
        background-color: $sixth-block-color;
      }

      &.seventh-block {
        background-color: $seventh-block-color;
      }

      &.eighth-block {
        background-color: $eighth-block-color;
      }

      &.soc-contr-block {
        background-color: $soc-contr-block-color;
      }
    }

    .row-header {
      width: 100%;
      //border-right: 1px solid #dee2e6;
      padding-left: $spacer;

      > * {
        @extend .h-100;
        @extend .w-100;
      }

      &.fired {
        background-color: $fired-color;
      }

      &.added {
        background-color: $added-color;
      }

      &.changed {
        background-color: $changed-color;
      }

      &.removed {
        background-color: $removed-color;
      }
    }

    .row-headers-container {
      position: sticky !important;
      left: 0;
      z-index: 4;
      padding: 0;

      //-webkit-box-shadow: 12px 0px 18px 10px rgb(34 60 80 / 20%);
      //-moz-box-shadow: 12px 0px 18px 10px rgba(34, 60, 80, 0.2);
      //box-shadow: 5px 0px 20px 5px rgb(34 60 80 / 20%);
      //padding-right: $spacer;
      background-color: white;

      .fill-cell {
        @extend .col-header;
        background-color: $common-block-color !important;
        transform: translate(0) !important;
        position: sticky !important;
        top: 0;
        z-index: 2;
        height: $col-height;
        width: 100%;
      }
    }

    .cell {
      //@extend .border;

      border-bottom: 1px solid #dee2e6 !important;

      // &:nth-last-of-type(1) {
      //     border-bottom: none;
      // }

      //&:nth-of-type(odd) {
      //    background-color: rgba(0, 0, 0, .05);
      //}

      width: $col-cell-width;
      height: $row-cell-height;

      &.changed {
        background-color: $changed-color;
      }
      &.added {
          background-color: $added-color;
      }
      &.removed {
          background-color: $removed-color;
      }
      .table > :not(caption) > * > * {
          padding: 0.2rem 0.5rem;
      }
    }

    .auto-calc-off {
      //background-color: $auto-calc-off-color !important;
      border: 2px solid $auto-calc-off-color;
    }
  }

  //.table {
  //    > :not(caption) > * > * {
  //        padding: 0;
  //    }
  //}
    .input-group.iconed > i:first-of-type {
        z-index: 2;
    }
}

.department-head {
  border-bottom: 1px solid $disabled-bg;
  min-height: 50px;
}

.summary {
  text-align: right;

  .value {
    font-weight: 700;
      .percent {
          font-weight: normal;
      }
  }

  .summary-delta {
    .value {
      color: $primary;
    }
  }
}

.mini-spoiler {
  .toggle {
    border: 1px solid $primary;
    margin: 5px 0px;
  }
}

app-wage-number-input-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .input-group {
    width: 10rem;
  }
}

app-wage-date-input-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .input-group {
    width: 10rem;
  }
}

app-wage-premium-per-quarter-header-cell,
app-wage-salary-per-month-header-cell,
app-wage-freeze-payroll-payout-header-cell,
app-wage-freeze-payroll-payout-header-cell,
app-wage-freeze-net-payroll-payout-cell,
app-wage-freeze-net-payroll-payout-cell,
app-wage-premium-per-quarter-view-only-cell,
app-wage-premium-per-quarter-cell,
app-wage-premium-per-quarter-header-cell,
app-wage-salary-per-month-view-only-cell,
app-wage-salary-per-month-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  td {
    width: 8.75rem !important;
  }
}

app-reject-button-cell {
    display: inline-block;
    min-width: 180px;
}

body > .wage-budget-select-dropdown {
  z-index: 9000 !important;
}

app-benchmarks-status {
  .tooltip-inner {
    max-width: 900px !important;
  }
}

.wage-settings-modal {
  @extend .d-flex;
  @extend .flex-grow-1;

  .modal-body {
    height: 80vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    overflow-y: overlay;
  }
}

.wage-settings-modal .color-border {
    padding-left: 30px !important;
    position: relative;
    &::before {
        position: absolute;
        top: 5px;
        left: 5px;
        height: calc(100% - 15px);
        width: 10px;
        display: block;
        content: "";
        border-radius: 5px;
    }
    &.common-block::before {
        background-color: darken($common-block-color, 10);
    }

    &.first-block::before {
        background-color: darken($first-block-color, 10);
    }

    &.second-block::before {
        background-color: darken($second-block-color, 10);
    }

    &.third-block::before {
        background-color: darken($third-block-color, 10);
    }

    &.fourth-block::before {
        background-color: darken($fourth-block-color, 10);
    }

    &.fifth-block::before {
        background-color: darken($fifth-block-color, 10);
    }

    &.sixth-block::before {
        background-color: darken($sixth-block-color, 10);
    }

    &.seventh-block::before {
        background-color: darken($seventh-block-color, 10);
    }

    &.eighth-block::before {
        background-color: darken($eighth-block-color, 10);
    }

    &.soc-contr-block::before {
        background-color: darken($soc-contr-block-color, 10);
    }
}

.delete-button-wrapper {
    z-index: 1;
    right: 0;
    .delete-btn {
        margin: 7px 10px;
    }
}

app-wage-cell-formula-container {
    app-wage-money-input-component {
        width: 100%;
        text-align: right;
    }
}

.autocalc-cell {
    app-switch {
        visibility: hidden;
    }
    &:hover {
        app-switch {
            visibility: visible;
        }
    }
}

app-wage-changes-log {
    .errors {
        font-weight: normal;
        .row {
            padding-bottom: 0.5rem;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

app-wage-payroll-per-month-cell-component {
    height: 100%;
}
app-group-plan .scrolling-area .row-headers-container .fill-cell {
    height: 55px;
}
app-group-plan .scrolling-area, app-wage-activity-view-component .scrolling-area {
    contain: none;
}
app-group-plan .scrolling-area .cell, app-group-plan .scrolling-area .row-header {
    height: 65px;
}
