@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

.cdk-overlay-container {
    z-index: 2150 !important;
}

.owl-dt-container {
    .owl-dt-calendar-cell-selected {
        background-color: map-get($theme-colors, 'primary') !important;
    }

    .owl-dt-container-info {
        color: map-get($theme-colors, 'secondary');
    }

    .owl-dt-container-info-active {
        color: map-get($theme-colors, 'dark');
    }

    .owl-dt-calendar-cell-content {
        font-size: 1em;
    }

    .owl-dt-calendar-cell-in-range {
        background-color: rgba(map-get($theme-colors, "primary"), .1) !important;
    }
}

.picker-extension-right .owl-dt-container-inner {
    display: flex;
}
