@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1)
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55)
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1)
  }
}

.waveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
  background-image: linear-gradient(to top, $primary 20%, darken($primary, 30) 80%);
}

.bgTop {
  z-index: 15;
  opacity: 0.5;
}

.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}

.bgBottom {
  z-index: 5;
}

.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.waveTop {
  background-size: 50% 100px;
}

.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.waveMiddle {
  background-size: 50% 120px;
}

.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}

.waveBottom {
  background-size: 50% 100px;
}

.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

app-login {
  //@extend .row;
  //@extend .justify-content-center;
  background: linear-gradient(45deg, rgba(147, 149, 152, 0) 30%, rgba(147, 149, 152, 1) 100%);
  height: 100%;
  margin: 0;

  .login-container {
    @extend .mb-5;
    //max-width: 95%;
    width: 25rem;
  }

  input:-webkit-autofill {
    box-shadow: inset 0 0 0 9999px white !important;
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .07em;
    font-variation-settings: 'wght' 500;
  }

  .login-content.card {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 0 1px rgba(0, 0, 0, .08) !important;
  }

  .btn-primary {
    &:focus,
    &:hover {
      box-shadow:
        0 14px 26px -12px rgba(19, 41, 75, .2),
        0 4px 23px 0 rgba(19, 41, 75, .2),
        0 8px 10px -5px rgba(19, 41, 75, .2) !important;
    }
  }

  .input-wrapper {
    @extend .mb-3;
    display: flex;
    align-items: center;

    > i {
      @extend .text-muted;
      width: 3rem;
      text-align: center;
      position: absolute;
      opacity: .5;
    }

    > input {
      padding-left: 3rem !important;
    }
  }


}

.login-btn {
  @extend .btn;
  @extend .btn-primary;
  width: 100%;
  font-size: 14px;
  padding: 15px;
  border: 0;
  background-color: darken($primary, 30) !important;
  color: #fff !important;
}
