app-calendar {
    .bg-primary-lighter {
        background-color: lighten($primary, 40);
    }
    .bg-primary-light {
        background-color: lighten($primary, 25);
    }
    .bg-primary {
        background-color: $primary;
        color: white;
    }
}
