$seachbar-color: #eee !default;
$expanded-searchbar-left: 1rem !default;
$expanded-searchbar-width: calc(100% - 2rem) !default;

app-searchbar {
  @extend .input-group;
  @extend .iconed;
  @extend .animated;
  overflow: hidden;
  min-width: 3rem;
  width: 3rem;
  flex-grow: 1;
  align-items: stretch;
  background-color: $seachbar-color;

  > .input-group {
    // opacity: 0;
    // transition: all .1s ease-in;

    > *:first-child {
      padding-left: 3em;
      padding-right: 3em;
    }
  }

//   :hover,
//   :focus-within {
//     opacity: 1;
//   }
  input {
      box-sizing: border-box;
    // border: 0 !important;
  }
}

@include media-breakpoint-down(xs) {
  app-searchbar:focus-within {
    //position: absolute;
    //left: $expanded-searchbar-left;
    //width: $expanded-searchbar-width;
    z-index: 50;
  }
}
