@mixin wizard-from() {
  .nav-link {
    border: 0 none;
    border-radius: 0;
    color: theme-color(secondary);

    &.active,
    &:hover,
    &:focus {
      color: theme-color(primary);
    }
  }

  .nav-item {
    .active {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 2px;
        height: 100%;
        background: theme-color(primary);
      }
    }
  }

  .tab-container {
    display: table;
  }

  .nav.nav-tabs {
    border-bottom: 0 none;
    border-right: 1px solid rgba(120, 130, 140, .13);
    display: table-cell;
    vertical-align: top;

    &::after {
      content: '';
      height: 1em;
      display: block;
    }
  }

  .tab-content {
    @extend .pt-3;
    @extend .ps-3;
    @extend .pb-3;
    display: table-cell;
    width: 100%;
    vertical-align: top;
  }
}
