app-toggle {
    .form-check-input {

        &:focus {
            background-image: escape-svg($form-switch-bg-image) !important;
        }

        &:checked {
            background-position: $form-switch-checked-bg-position !important;
            background-image: escape-svg($form-switch-bg-image) !important;
            background-color: #fff;
        }
    }
}
