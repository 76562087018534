app-wage-analytics-component {
    .grid-container {
        display: grid;
        grid-template-columns: 0.6fr 1.2fr 1.6fr 0.6fr;
        grid-template-rows: 0.5fr 0.8fr 1.7fr;
        gap: $spacer $spacer;
        grid-auto-flow: row;
        grid-auto-rows: minmax(min-content, max-content);
        grid-auto-columns: minmax(min-content, max-content);
        grid-template-areas:
    "controls second fourth fifth"
    "first second fourth fifth"
    "first third fourth fifth";
    }

    .grid-item {
        .inner {
            @extend .h-100;
            @extend .card;
            @extend .card-body;
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 0;
            }

            &:hover::-webkit-scrollbar {
                width: 2px;
            }

            &::-webkit-scrollbar-button:single-button:vertical:decrement {
                background-image: none;
            }

            &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
                background-image: none;
            }

            &::-webkit-scrollbar-button:single-button:vertical:increment {
                background-image: none;
            }

            &::-webkit-scrollbar-button:single-button:vertical:increment:active {
                background-image: none;
            }
        }
    }

    .controls {
        grid-area: controls;
    }

    .first {
        grid-area: first;
    }

    .fourth {
        grid-area: fourth;
    }

    .fifth {
        grid-area: fifth;
    }

    .second {
        grid-area: second;
    }

    .third {
        grid-area: third;
    }


    app-wage-grouped-widget-component {
        .grouped-widget-title-wrapper {
            @extend .p-3;
            border-bottom: 1px solid $gray-300;

            .grouped-widget-title {
                border-left: 3px solid $primary;
                margin-bottom: $spacer;
                margin-left: -$spacer;
                padding-left: $spacer;
            }
        }
    }

    .chart-wrapper {
        .chart-title {
            color: $text-muted;
        }
    }

    ul.wage-indicators {
        @extend .list-group;

        li {
            @extend .list-group-item;
            border-left: none;
            border-right: none;

            &:first-child {
                border-top: none;
            }

            &:last-child {
                border-bottom: none;
            }

            .wage-indicator-param {
                padding: 0;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }
        }
    }

}
