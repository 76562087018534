$col-cell-width: auto;
$row-cell-height: 65px;
$col-height: 80px;
$footer-height: 65px;
$common-block-color: #DDDDDD;
$budget-block-color: #FFE699;
$fact-block-color: #FFF2CC;
$fact-alt-block-color: #FFD966;
$requests-block-color: #ACB9CA;
$current-block-color: #C6E0B4;
$distribution-block-color: #D9E1F2;
$changed-color: lighten($warning, 40);
$added-color: lighten($primary, 50);
$removed-color: lighten($danger, 40);

overhead-expenses-budget-component {
  @extend .border-0;
  @extend .shadow-sharp;
  @extend .bg-white;

  * {
    font-size: 0.85rem;
  }

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 1px;

  .scrolling-area {
    margin-bottom: 0;

    .scrollable-content {
      width: auto;
      height: auto;
    }

    flex-grow: 1;
    height: 1px;
    contain: strict;
    transform: translateZ(0);
    will-change: scroll-position;

    .header-decoration {
      background: #fff;
      box-shadow: 3px 0 5px 0 rgba(0, 0, 0, .2);
      border-bottom: 1px solid #dee2e6 !important;

      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }

    .vertical-scroller {
      margin: 0;

      > .scrollable-content {
        display: flex;
      }
    }

    .col-header,
    .cell {
      @extend .px-3;
      width: $col-cell-width;
    }

    .row-header,
    .cell {
      height: $row-cell-height;
      position: relative;

      > * {
        width: 100%;
        height: 100%;
      }

      &.hovered {
        box-shadow: 0 0 10px rgb(0 0 0 / 0.3);
        background-color: white;
        position: relative;
        z-index: 1;

        &:before {
          display: block;
          content: "";
          background-color: white;
          width: 8px;
          height: 100%;
          position: absolute;
          left: -8px;
          top: 0;
        }

        &.added {
          background-color: $added-color;

          &:before {
            background-color: $added-color;
          }
        }

        &.restored {
          background-color: $added-color;

          &:before {
            background-color: $added-color;
          }
        }

        &.removed {
          background-color: $removed-color;

          &:before {
            background-color: $removed-color;
          }
        }

        > * {
          font-weight: 700;
        }
      }

      &.added {
        background-color: $added-color;
      }

      &.restored {
        background-color: $added-color;
      }

      &.removed {
        background-color: $removed-color;
      }

      &.changed {
        background-color: $changed-color;
        $start: 1;
        $end: 12;
        @for $i from $start through $end {
          &.changed_#{$i} {
              &:before {
                  background-color: white;
              }
              table {
                  &:before {
                      display: block;
                      content: "";
                      background-color: white;
                      width: 17px;
                      height: 100%;
                      position: absolute;
                      left: -3px;
                      top: 0;
                  }
                  &:after {
                      display: block;
                      content: "";
                      background-color: white;
                      width: 17px;
                      height: 100%;
                      position: absolute;
                      right: -3px;
                      top: 0;
                  }
                  background-color: white;
                  td:nth-child(#{$i}) {
                      background-color: $changed-color;
                      .ignore_changed {
                          background-color: white;
                      }
                  }
              }
          }
        }
      }
    }

    .row-header {
      &.hovered {
        &:before {
          display: block;
          content: "";
          background-color: rgb(0 0 0 / 0.3);
          width: $spacer * 0.5;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .col-header,
    .row-header {
      @extend th;
      @extend .header-decoration;
    }

    .footer-cell {
      @extend .header-decoration;
      position: sticky;
      bottom: -1px;
      height: $footer-height;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @extend .px-3;
      border-top: 1px solid darken($common-block-color, 10) !important;
      background-color: $common-block-color;
      font-weight: 500 !important;
      z-index: 2;
    }

    .col-header {
      position: sticky;
      top: 0;
      z-index: 3;
      display: flex;
      align-items: flex-start;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      justify-content: flex-start;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      height: $col-height;

      .col-header-caption {
        max-width: 12rem;
        text-align: start;
        white-space: normal !important;
      }

      &.common-block {
        background-color: $common-block-color;
      }

      &.fact-block {
        background-color: $fact-block-color
      }

      &.fact-alt-block {
        background-color: $fact-alt-block-color;
      }

      &.requests-block {
        background-color: $requests-block-color;
      }

      &.current-block {
        background-color: $current-block-color;
      }

      &.budget-block {
        background-color: $budget-block-color;
      }

      &.distribution-block {
        background-color: $distribution-block-color;
      }
    }

    .row-header {
      width: 100%;
      //border-right: 1px solid #dee2e6;
      padding-left: $spacer;

      > * {
        @extend .h-100;
        @extend .w-100;
      }

      &.added {
        background-color: $added-color;
      }

      &.removed {
        background-color: $removed-color;
      }

      &.changed {
        background-color: $changed-color;
      }
    }

    .row-headers-container {
      position: sticky !important;
      left: 0;
      z-index: 4;
      padding: 0;

      background-color: white;

      .fill-cell {
        @extend .col-header;
        background-color: $common-block-color !important;
        transform: translate(0) !important;
        position: sticky !important;
        top: 0;
        z-index: 2;
        height: $col-height;
        width: 100%;
      }
    }

    .cell {
      //@extend .border;

      border-bottom: 1px solid #dee2e6 !important;

      width: $col-cell-width;
      height: $row-cell-height;

      &.added {
        background-color: $added-color;
      }

      &.removed {
        background-color: $removed-color;
      }

      &.changed {
        background-color: $changed-color;
      }
    }
  }

  .input-group.iconed > i:first-of-type {
    z-index: 2;
  }

  .control-button-wrapper {
    @extend .position-absolute;
    right: 0;
    top: 50%;
    z-index: 3;

    .btn {
      transform: translateY(-50%);
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  overflow: hidden !important;
}

.overhead-expenses-settings-modal {
  @extend .d-flex;
  @extend .flex-grow-1;

  .modal-body {
    height: 80vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    overflow-y: overlay;
  }
}

.overhead-expenses-settings-modal .color-border {
  padding-left: 30px !important;
  position: relative;

  &::before {
    position: absolute;
    top: 5px;
    left: 5px;
    height: calc(100% - 15px);
    width: 10px;
    display: block;
    content: "";
    border-radius: 5px;
  }

  &.common-block::before {
    background-color: darken($common-block-color, 10);
  }

  &.fact-block::before {
    background-color: darken($fact-block-color, 10);
  }

  &.fact-alt-block::before {
    background-color: darken($fact-alt-block-color, 10);
  }

  &.requests-block::before {
    background-color: darken($requests-block-color, 10);
  }

  &.current-block::before {
    background-color: darken($current-block-color, 10);
  }

  &.budget-block::before {
    background-color: darken($budget-block-color, 10);
  }

  &.distribution-block::before {
    background-color: darken($distribution-block-color, 10);
  }
}

app-overhead-expenses-changes-log-component {
  .small {
    font-size: 14px;
  }
}

app-overhead-expenses-distribution-per-month-plan-fact-cell {
    height: 100%;
    width: 100%;
    .input-group.iconed > input:first-of-type {
        padding-left: 5px;
    }
    .table > :not(caption) > * > * {
        padding: 0;
    }
}


$start: 1;
$end: 12;
@for $i from $start through $end {
    .changed_#{$i} {
        app-overhead-expenses-distribution-per-month-plan-fact-cell  table {
            td:nth-child(#{$i}) .col-6:nth-child(1){
                background-color: $changed-color;
            }
        }
    }
    .changed_fact_#{$i} {
        app-overhead-expenses-distribution-per-month-plan-fact-cell  table {
            td:nth-child(#{$i}) .col-6:nth-child(2){
                background-color: $changed-color;
            }
        }
    }
}

.controls-buttons * {
  white-space: nowrap;
}


.controls-buttons.collapsed {
  display: none;
}
