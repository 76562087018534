.bi-custom {
    &:before {
        content: "";
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;

    }
    &-trash{
        &:before {
            background-image: url("/assets/icons/delete.svg");
        }
    }
    &-person{
        &:before {
            background-image: url("/assets/icons/clean_person.svg");
        }
    }
}

.btn-close-container{
    .btn{
        padding: 0;
    }
}
