/**
  * Depends on bootstrap
*/

/* Initial layout */
html,
body {
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.input-group.iconed {
    align-items: center;

    > i:first-of-type {
        align-self: center;
        position: absolute;
        z-index: 4;
        left: 1em;
        opacity: .5;
    }

    > i.icon-btn {
        //align-self: center;
        position: absolute;
        z-index: 5;
        //right: 1em;
        opacity: .5;
        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }

    > input:first-of-type {
        padding-left: 2.5em;
        z-index: 1;
    }
    //&.right {
    //    > input:first-of-type {
    //        padding-right: 2.5em;
    //        z-index: 1;
    //    }
    //}
}

.icon-button {
    @extend .animated;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        @extend .text-primary;
        text-shadow: 0 0 .1em rgba(0, 0, 0, .3);
    }
}

.form-as-info {
    .ng-clear-wrapper,
    .ng-arrow-wrapper {
        opacity: 0;
    }

    *[disabled] {
        background-color: transparent;
    }

    ng-select,
    ng-select * {
        background-color: transparent !important;
    }
}

/* Helpers */
.my-show {
    opacity: 1;
}

.my-hide {
    opacity: 0;
    pointer-events: none;
}

/*  Global helpers */
@mixin animated($what: all, $for: .1s) {
    transition: $what $for ease-in;
}

@mixin animated-long($what: all, $for: .3s) {
    transition: $what $for ease-in;
}

.animated {
    @include animated();
}

.animated-long {
    @include animated-long(all);
}

.disabled {
    pointer-events: none;
    user-select: none;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered-x {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-1 {
    opacity: 1 !important;
}

.hyphens {
    hyphens: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-control-label {
    cursor: pointer;
}

.pointer-events-none {
    pointer-events: none;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text-overflow-ellipsis-title {
    @extend .text-overflow-ellipsis;

    &::after {
        content: attr(title);
    }
}

@mixin shadow-sharp($alpha: .4) {
    box-shadow: 0 1px 4px rgba(0, 0, 0, $alpha) !important;
}

.shadow-sharp {
    @include shadow-sharp();
}

/* common styles imports */
@import 'btn-navbar';
@import 'loader';

/* node_modules imports */

// https://github.com/scttcper/ngx-toastr/issues/602
#toast-container > div {
    opacity: 1;
}

//@import '~ngx-toastr/toastr-bs4-alert';
@import 'drawer';
@import 'picker';
@import 'select';

@import 'toastr';
@import 'scrollbar';
@import 'avatars';
