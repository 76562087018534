@import './wizard-modal/wizard-modal.component';

.modal-body {
  white-space: pre-line;
}

.modal-shared {
    max-width: 1285px
}

.modal-expenses {
    max-height: 30em
}
