@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import 'assets/fonts/norms/stylesheet.css';
@import 'assets/fonts/golos/stylesheet.css';

$primary: #0553C7;
$primary-lighten: lighten($primary, 30);
//$secondary: rgb(206, 212, 218);
$secondary: lighten(#53576A, 30);
$secondary-lighten: lighten($secondary, 15);
$warning: #D57C01;
$success: #1b7745;
$status-success: #47B299;
$danger: #D72923;
$status-danger: #FF704D;
$text-muted: #8C95A6;
$disabled: #D0D3D9;
$light: #F0F0F0;
$dark: #000000;
$selected: #E8F1FF;
$selected-dark: #97BDF4;
$selected-gray: #DDDDDD;
//$primary: #13294b;
//$secondary: #4a4c64;
//$enable-caret: true;

:root{
    --color-primary: #{$primary};
    --color-disabled: #{$disabled};
}

//$font-family-base: 'TT Norms', sans-serif;
//$font-weight-base: 400;
//$font-weight-bold: 600;
$font-family-base: 'Golos UI', sans-serif;
$font-weight-base: 400;
$font-weight-bold: 700;
$headings-font-family: 'Montserrat', sans-serif;

// Miscellaneous
$body-color: #000;
$disabled-bg: rgba(0, 0, 0, 0.1);
$body-bg: #F0F0F0;
$table-bg: transparent;
$list-group-bg: transparent;
$border-radius: 2px;
$form-check-input-border-radius: $border-radius;

$table-striped-bg-factor: 0.005;

$input-bg: #fff;
$input-disabled-bg: $disabled-bg;

$input-focus-box-shadow: none;
$input-box-shadow: none;
$input-btn-focus-box-shadow: none;
$input-focus-width: 0;

//$input-disabled-bg: $body-bg;
//$component-active-bg: lighten($primary, 10);
//$nav-link-color: $body-bg;
//$nav-link-hover-color: $body-bg;
//$nav-pills-link-active-color: $body-color;
//$nav-pills-link-active-bg: $body-bg;

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons';
@import 'bootstrap/scss/vendor/rfs';

@import 'app/shared/styles/index';

html {
    font-size: 14px;
}

* {
    font-variant-ligatures: none;
}

app-table {
    table {
        border-collapse: separate;
        border-spacing: 0;
    }

    th {
        @extend .fw-bold;
        // font-variation-settings: 'wght' 400;
        border-bottom: 1px solid $gray-300 !important;
    }

    td {
        @extend .fw-normal;
        // font-variation-settings: 'wght' 400;
        padding: 0.75rem !important;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgb(74, 76, 100, 0.03);
    }

    .table > :not(:first-child) {
        border-top: none;
    }

    //   thead th {

    //   }
}

input[type='money'],
input[type='number'] {
    text-align: right;
}

input[type='money']:disabled::placeholder {
    //color: $form-text-color;
}

input[type='money']::placeholder {
    //color: $body-color;
}

input[type='checkbox'] {
    border-color: $primary;
}

.popover {
    will-change: transform;
    max-width: 100% !important;
    border: none;
    @extend .shadow-sharp;
}

.popover-body {
    will-change: transform;
    max-width: 100% !important;
}

.top-right-corner {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(43%, -40%);
    align-items: center;
    display: flex;
    justify-content: center;
}

.timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem;
}

@media (min-width: 768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
        content: '';
        display: block;
        border-top: 0.25rem dotted #3b82f6;
        width: 3.46rem;
        position: absolute;
        left: 7.5rem;
        top: 0.3125rem;
    }
    .timeline-steps .timeline-step:not(:first-child):before {
        content: '';
        display: block;
        border-top: 0.25rem dotted #3b82f6;
        width: 3.8125rem;
        position: absolute;
        right: 7.5rem;
        top: 0.3125rem;
    }
}

.timeline-steps .timeline-content {
    width: 8rem;
    text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #3b82f6;
}

.timeline-steps .timeline-content .inner-circle:before {
    content: '';
    background-color: #3b82f6;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    border-radius: 6.25rem;
    opacity: 0.5;
}

.ngx-contextmenu .dropdown-menu {
}

.ngx-contextmenu li {

}

.ngx-contextmenu li:first-child {

}

.ngx-contextmenu a {
    @extend .dropdown-item
}

.ngx-contextmenu a:hover {

}

.multiline-option {
    white-space: break-spaces;
}


app-sidebar {

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color: white;
        color: $primary;
    }

    .nav-link-dropdown,
    .nav-link {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .nav-link-dropdown {
        text-decoration: none;
    }

    .nav-flush .nav-link {
        border-radius: 0;
    }

    .nav-link {
        padding: 0;
    }

    .nav-link-dropdown.active,
    .nav-link.active {
        -webkit-clip-path: url(#my-clip-path);
        clip-path: url(#my-clip-path);
        background-color: $selected-gray !important;
        color: $primary !important;
    }
}

.nav-caption {
    font-size: 0.743rem;
    font-weight: 500;
    color: $primary;
    text-decoration: none !important;
    word-spacing: 9999px;
}

ng-dropdown-panel.ng-dropdown-panel-wider {
    width: 20rem !important;
}

ng-dropdown-panel.ng-dropdown-no-options .ng-dropdown-panel-items {
    display: none !important;
}

.ng-select .ng-clear-wrapper .ng-clear {
    font-size: inherit !important;
    color: $danger;
}

.input-group-seamless {
    .input-group-text {
        background-color: white;
        border-right: none;
    }

    > .form-control:not(:first-child) {
        border-left: none;
    }

    .form-control {
        border-right: none;
    }

    .btn {
        background-color: white;
        border-left: none;
        border-color: $input-border-color;
    }
}

.navigation {
    a.active {
        color: $body-color;
        font-weight: 700;
        text-decoration: none;
    }
}

.font-inherit {
    font-size: inherit;
}

.w-90 {
    width: 90%;
}

.bold{
    font-weight: bold;
}

.scrollable-sm {
    max-height: 10em;
    overflow: scroll;
    overflow-x: hidden;
    box-shadow: 0px 2px 9px 2px rgba(34, 60, 80, 0.2) inset;
    padding: 5px;
}

.bg-primary-light {
    background-color: $primary-lighten;
}
.bg-secondary-light {
    background-color: $secondary-lighten;
}

.cdk-global-scrollblock {
    overflow: hidden;
}

app-aggregate {
    .table-aggregate {
        thead {
            position: -webkit-sticky;
            position: sticky;
            top: 70px;
            z-index: 2;
            height: 45px;
        }
    }
}

.table-aggregate {
    border-collapse: collapse;

    position: relative;

    thead {
        position: -webkit-sticky;
        position: sticky;
        th {
            word-break: break-word;
        }
    }

    tr {
        border-bottom: 1px solid var(--color-disabled);
        &.bg-primary, &.bg-primary-light {
            border: none;
        }
    }
    td {
        padding: .5rem;
    }
}

$budget-colors: (
        "1": $secondary,
        "2": $warning,
        "3": $status-success,
        "4": $status-danger,
        "5": $warning,
        "6": $status-success,
        "7": $primary,
        "8": lighten($primary, 30),
) !default;

@each $color, $value in $budget-colors {
    .budget-status-#{$color} {
        @extend .badge;
        color: color-yiq($value);
        background-color: $value;
        font-weight: normal;

        @at-root a#{&} {
            &:focus,
            &.focus {
                outline: 0;
                box-shadow: 0 0 0 5px rgba($value, .5);
            }
        }
    }
}

.budget-status-2 {
    background-color: lighten($warning, 40);
    color: black;
}

.budget-status-5 {
    background-color: lighten($warning, 40);
    color: black;
}

.flex-width{
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

app-filter-header-money-cell .ng-placeholder {
    padding-left: 3rem;
}

app-wage-budget-merge-modal app-table table thead th:last-child {
    vertical-align: bottom;
}
.badge {
    font-size: 0.85em;
}

@import 'app/layout/main/main-layout.component';
@import 'app/layout/card/card.component';
@import 'app/pages/login/login.component';
@import 'app/modules/wage/shared/wage';
@import 'app/modules/wage/analytics/wage-analytics.component';
@import 'app/modules/wage/shared/changes-log/wage-changes-log';
@import 'app/modules/wage/fund/outsource/outsource';
@import 'app/modules/wage/consolidated-report/consolidated-report.scss';
@import 'app/modules/wage/vacancy/vacancy-close';
@import 'app/modules/competition/competition.scss';
@import 'app/modules/notification/notification/notification.component';

@import 'app/pages/dashboard/financial-report/dashboard-financial-report';

@import 'app/shared/modules/table/index';
@import 'app/shared/modules/modal/index';
@import 'app/shared/modules/searchbar/index';
@import 'app/shared/modules/forms/index';
@import 'app/shared/components/avatar/avatar';
@import 'app/shared/components/calendar/calendar';
@import 'app/shared/components/tree-table/tree-table.component';
@import 'app/shared/components/formula-input/formula-input';
@import 'app/shared/components/sorter/sorter';
@import 'app/shared/widgets/widgets';
@import 'app/shared/styles/custom-icons';

@import 'app/shared/styles/tabs';


@import 'app/modules/overhead-expenses/overhead-expenses';

@import '@fortawesome/fontawesome-free/css/all.css';

@import '@angular/cdk/overlay-prebuilt.css';
@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';

