app-dashboard-financial-report {
    .grid-container {
        display: grid;
        grid-template-columns: 0.75fr 1.25fr 1.25fr 0.75fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: $spacer $spacer;
        grid-auto-flow: row;
        grid-auto-rows: minmax(min-content, max-content);
        grid-auto-columns: minmax(min-content, max-content);
        grid-template-areas:
            "controls budget revenue clients"
            "media budget revenue clients"
            "media profit profits-and-commits clients"
            "media profit profits-and-commits clients"
            "media profit-categories expenses-categories clients"
            "media profit-categories expenses-categories clients";
    }

    .grid-item {
        @extend .h-100;
        .inner {
            @extend .h-100;
            @extend .card;
            @extend .card-body;
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 0;
            }

            &:hover::-webkit-scrollbar {
                width: 2px;
            }

            &::-webkit-scrollbar-button:single-button:vertical:decrement {
                background-image: none;
            }

            &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
                background-image: none;
            }

            &::-webkit-scrollbar-button:single-button:vertical:increment {
                background-image: none;
            }

            &::-webkit-scrollbar-button:single-button:vertical:increment:active {
                background-image: none;
            }
        }
    }

    .budget {
        grid-area: budget;
    }

    .profit {
        grid-area: profit;
    }

    .profit-categories {
        grid-area: profit-categories;
    }

    .controls {
        grid-area: controls;
    }

    .media {
        grid-area: media;
    }

    .revenue {
        grid-area: revenue;
    }

    .profits-and-commits {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: $spacer $spacer;
        grid-auto-flow: row;
        grid-template-areas:
    "profits-circle commits-circle";
        grid-area: profits-and-commits;
    }

    .profits-circle {
        grid-area: profits-circle;
    }

    .commits-circle {
        grid-area: commits-circle;
    }

    .expenses-categories {
        grid-area: expenses-categories;
    }

    .clients {
        grid-area: clients;
    }

    ul.indicators {
        @extend .list-group;

        li {
            @extend .list-group-item;
            border-left: none;
            border-right: none;

            &:first-child {
                border-top: none;
            }

            &:last-child {
                border-bottom: none;
            }

            .indicator-param {
                padding: 0;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }
        }
    }

}
