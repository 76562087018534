app-header-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;

  .container, .container-fluid {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  .table-header {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .2);
    width: 100%;
    background: $table-background;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
