app-wage-changes-log {
    app-wage-changes-log-items-list {
      display: flex;
      flex-direction: column;
      //flex-grow: 1;
      //height: calc(100vh - 98.5px - 1 * #{$spacer});
      -webkit-overflow-scrolling: touch;
      overflow-y: overlay;
    }
}
