.head-tabs {
    .nav-item {
        flex-grow: 1;
        text-align: center;
    }

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        background-color: inherit;
        font-weight: bold;
        border: none;
    }
    .nav-tabs {
        border: none;
    }

    .nav-tabs .nav-item {
        border-bottom: 1px solid #dee2e6;
    }

    .nav-tabs .nav-item.active {
        border-bottom: 1px solid #0445A6;
    }
}

